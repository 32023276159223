@import "mixins";

.asset {
  @include flex(space-between, flex-start);
  border-top: 1px solid var(--card-border);
  padding: 20px 0;
  position: relative; // to loading indicator
}

.details {
  @include flex(space-between, flex-start);
  gap: 10px;
}

.symbol,
.amount {
  font-size: 16px;
}

.amount {
  font-weight: var(--normal);
}

.value {
  color: var(--text-muted);
  font-size: var(--font-size-small);
}
