@import "mixins";

.list {
  display: grid;
  gap: var(--grid-gap);

  @include desktop {
    grid-template-columns: repeat(2, 1fr);
  }
}

.link {
  display: grid;
  color: inherit;

  &:hover {
    text-decoration: none;
  }
}

/* pagination */
.pagination {
  display: flex;
  justify-content: flex-end;
}
