@import "mixins";

.flag {
  position: relative;

  .label,
  .line {
    transform: translate(-50%, 0);
  }
}

.label {
  display: inline-block;
  font-size: var(--font-size-small);
}

.line {
  display: block;
  background: var(--text);
  width: 1px;
  height: 12px;
}

/* progress bar */
.track {
  background: var(--bg-muted);
  overflow: hidden;
  width: 100%;
}

.item {
  height: 10px;
}
