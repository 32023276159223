@import "mixins";

.orb {
  --size: 100px;
  @include flex-column(flex-end, stretch);

  background: var(--bg-muted);
  border-radius: 50%;
  overflow: hidden;
  width: var(--size);
  height: var(--size);
}

.large {
  --size: 120px;
}

.filled {
  flex: none;
  background: var(--success);
  position: relative;
}

.tilde {
  position: absolute;
  top: calc(-1 * var(--size) / 20);
  left: 0;
  right: 0;

  fill: var(--success);
  width: var(--size);
  height: calc(var(--size) / 10);
}
