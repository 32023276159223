@import "mixins";

.list {
  overflow: auto;
}

.horizontal {
  @include flex(space-evenly);
  text-align: center;
}

.vertical {
  display: grid;
  gap: 20px;
}

.title {
  font-size: var(--font-size-small);
}
