@import "mixins";

.item {
  --padding: 8px 12px;

  border: 1px solid var(--card-border);
  border-radius: var(--border-radius);
  text-align: left;
  width: 100%;
  height: 100%;
}

.main {
  padding: var(--padding);
}

.name {
  color: var(--text-muted);
  font-size: 11px;
  font-weight: var(--normal);
}

.footer {
  border-top: 1px solid var(--card-border);
  font-size: var(--font-size-small);
  font-weight: var(--normal);
  padding: var(--padding);
}

.balance {
  @include inline-flex;
  gap: 4px;
  line-height: 1;
}

/* grid */
.grid {
  display: grid;
  gap: 12px;

  @include desktop {
    grid-template-columns: repeat(3, 1fr);
  }

  &.single {
    grid-template-columns: repeat(1, 1fr);
  }

  @include mobile {
    grid-template-columns: repeat(1, 1fr);
  }
}
