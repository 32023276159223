.button {
  border: var(--border-width) solid var(--card-border);
  border-radius: var(--border-radius);
  color: inherit;
  font-weight: var(--normal);
  transition: all var(--transition);

  &:hover,
  &.active {
    background: var(--bg-muted);
    text-decoration: none;
  }

  &.active {
    border-color: var(--button-primary-bg);
  }
}
