@import "mixins";

.item {
  @include flex;

  gap: 12px;
  padding: 20px 0;

  &:not(.compact) {
    flex-direction: column;
  }
}

.compact {
  @include flex(flex-start);

  &:not(:first-child) {
    border-top: 1px solid var(--card-border);
  }
}

.image {
  border-radius: 4px;
  cursor: zoom-in;
  overflow: hidden;
}

.large {
  border-radius: var(--border-radius);
  width: 100%;
  height: auto;
}

.name {
  flex: 1;
}

/* placeholder */
.placeholder {
  content: "";
  background: var(--bg-muted);
}
